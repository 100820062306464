<template>
  <router-view/>
</template>

<script>
  export default {
    name: 'App',
    mounted() {
      if (process.env.VUE_APP_ENVIRONMENT === "production") {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.dataset.websiteId = '8ad850d1-ef08-4959-aec1-45b8b900fd49';
        script.src = "https://red-blue-stories.herokuapp.com/umami.js"
        document.head.appendChild(script)
      }

      let client = ShopifyBuy.buildClient({
        domain: 'checkout.redbluestories.com',
        storefrontAccessToken: 'f814544bec8ac71fd4567fdc48096b0e',
      })
      this.$store.commit('setShopifyClient', client);

      window.open = function (open) {
        return function (url, name, features) {
        // Pass through non-shopify URLS
        if (url.indexOf("checkouts") < 0) {
          return open.call(window, url, name, features);
        }

        // Use current window
          console.log("Redirecting to:", url);
          window.location.href = url;
          return null;
          };
        }(window.open);

    }
  }
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;0,800;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap');
@import 'assets/styles/variables.scss';
@import 'assets/styles/main.scss';

</style>
