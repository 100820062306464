<template>
  <div class="bg-blue">
  <Nav color="teal"></Nav>
  <section id="hero" class="container-large">
    <div class="row teal">
      <div class="left">
        <h1>
          Interactive stories<br> for little humans
        </h1>
        <p class="subtitle">From puzzles to children’s books and postcards, we take traditional formats and give them a twist - Augmented Reality, paper cutouts, choose-your-own-adventure, you name it!</p>
        <button class="light" @click="goToShop()">Shop interactive stories</button>
      </div>
      <div class="right">
        <img src="../assets/img/hero_shelves.png" id="hero-img">
      </div>
    </div>
  </section>
  <section id="products" class="container-large">
    <div class="card fg card-container blue">
      <h2 class="extra-mb">Our latest titles</h2>
      <div class="product-grid grid-3">
        <ProductLearnMore v-for="product in products" v-bind:key="product.name" :product="product" ></ProductLearnMore>
      </div>
    </div>
  </section>
  <section id="interactive-stories" class="container-large">
    <div class="row teal gap-3">
      <div class="left-gap-3">
        <h2>
          Why interactive stories?
        </h2>
      </div>
      <div class="right-gap-3">
        <p class="large-text">Interactive stories - or stories in which the reader has tools to influence the story in some way, are fun to play, engaging, and have a lasting impact on readers. Having to make choices and imagining different outcomes to these choices forces us to use our imagination, thus allowing us to <b>connect better with the story that we are experiencing.</b><br><br>
We strongly believe that the future of interactive stories is in developing novel ideas where user’s interactions happen through multiple mediums such as physical and digital, or paper and light.</p>
      </div>
    </div>
  </section>
  <section id="reviews-section" class="container-large">
    <div class="card fg card-container blue bg-teal">
      <div class="col">
        <h2 class="extra-mb">Praises for Red + Blue Stories</h2>
        <div class="row gap-3">
          <div class="col left-gap-3">
            <p class="review-text">“The Bear Who Touched the Northern Lights” Is a Charming AR Story Puzzle. [...]
              if you’re letting your child construct this AR story puzzle without you, you’re missing out.</p>
            <p class="review-author">Jon Jaehnig , AR Post</p>
            <a href="https://arpost.co/2023/02/22/bear-touched-northern-lights-ar-story-puzzle/" class="button-outline" target="_blank">Read Article</a>
          </div>
          <div class="col gap-2 right-gap-3">
            <div class="small-review">
              <p class="review-text">"The story is charming and I love the animations!"</p>
              <p class="review-author">Charity, parent of a 10 y.o (about The Bear Who Touched The Northern Lights)</p>
            </div>
            <div class="small-review">
              <p class="review-text">"It’s fun figuring out all the options!"</p>
              <p class="review-author">Elliot, 6 y.o (about The Bear Who Touched The Northern Lights)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section  id="about" class="container-large">
    <div class="row teal gap-3">
      <div class="left-gap-3">
        <h2>
          About Red + Blue Stories
        </h2>
        <p class="large-text">Red + Blue Stories, run by Karl Kim and Julie Puech, is a couple of creatives’ endeavours to craft interactive, cross-media storytelling experiences with compelling narratives that spark excitement and wonder.</p>
        <p class="large-text">From our home in Squamish, BC, we explore ways to tell stories that let readers be fully immersed. We develop products that explore how different format interact with each other, that question common storytelling format, and most of all that let users make choices!</p>
      </div>
      <div class="right-gap-3">
        <img class="card" v-lazy="{src: require('../assets/img/RBS_event_03.jpg'), loading: require('../assets/img/RBS_event_03_loading.jpg')}" alt="Tangible Interaction market in December 2023">
      </div>
      <div class="left-gap-3">
        <img class="card" v-lazy="{src: require('../assets/img/RBS_event_01.png'), loading: require('../assets/img/RBS_event_01_loading.png')}" alt="picture of the bear who touched the northern lights in the Little Bookshop in Squamish">
      </div>
      <div class="right-gap-3">
        <p class="large-text">We have a lot of imagination and many stories we want to tell… though when it came to naming our studio, we just went by the colour of our hair!</p>
        <p class="large-text">We do everything ourselves - from writing to illustration to hand-drawn animations to the development of our companion apps! We sketch a lot, try many concepts, and ultimately get joy from bringing interactive experiences to life!</p>
        <p class="large-text">It’s been a fun journey, and it has become a beautiful human endeavour as we experience to see the joy our interactive products bring to little humans (and bigger ones too!).</p>
      </div>
    </div>
  </section>
  <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Nav from "@/components/Nav";
import router from "../router/index.js";
import ProductLearnMore from "@/components/ProductLearnMore";
import BuyButtonSample from "@/components/BuyButtonSample";

export default {
  name: 'Home',
  components: {
    BuyButtonSample,
    ProductLearnMore,
    Footer,
    Nav
  },
  data () {
    return {
      isCompact: false,
      isMobile: false,
      cardHover : false,
      currentCardNumber: '-1',
    }
  },
  methods: {
    resizeHandler() {
      this.isCompact = window.innerWidth < 1024;
      this.isMobile = window.innerWidth < 769;
    },
    triggerMobileMenu(state) {
      this.mobileMenu = state;
    },
    cardHovered(cardNb, state) {
      this.cardHover = state;
      if (state)
        this.currentCardNumber = cardNb;
      else
        this.currentCardNumber = -1;
    },
    productPage() {
      router.push({name: 'TheBearWhoTouchedTheNorthernLights'});
    },
    goToShop() {
      router.push({name: 'shop'});
    }
  },
  computed: {
    products() {
      return this.$store.getters.getFeaturedProducts;
    }
  },
  created() {
    this.isCompact = window.innerWidth < 1068;
    this.isMobile = window.innerWidth < 769;
    window.addEventListener("resize", this.resizeHandler);

    let vh = window.innerHeight * 0.01;
    let vw = window.innerWidth * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  }

}
</script>

<style lang="scss">
@import "../assets/styles/variables.scss";

#hero {
  z-index: 700;
  background-color: $blue;
  padding-top: 3rem;
  height: 100vh;
  min-height: 900px;

  .row {
    align-items: flex-end;
    height: 80%;
    margin-bottom: 20%;
  }
  .left {
    flex-basis: 45%;
    padding-bottom: 2rem;
  }
  .right {
    flex-basis: 55%;
  }

  p.subtitle {
    padding-right: 2em;
  }
}

#products {
  position: relative;

  &.card-container {
    max-width: 90vw;
  }
}

.product-list {
  gap: 3em;
}

.products {
  max-width: calc(100% - 200px - 3em);
  gap: 3em;
}

.product {
  flex-basis: calc(31% - 1.5em);
}

.basis-small {
  flex-basis: 200px;
}

#hero-img {
  position: relative;
  z-index: 999;
}

#interactive-stories {
  margin: 8rem auto;
  //padding-bottom: 4rem;
  .row {
    align-items: center;
  }
}

#reviews-section {
  position: relative;

  p.review-text {
    font-weight: 600;
  }
}

#about {
  margin: 8rem auto 2rem auto;
  .row {
    align-items: center;

    p.large-text {
      padding-right: 3rem;
    }
  }
}

@media only (min-width: 1024px) and (max-width: 1280px) {

}

@media only screen and (max-width: 1023px) {
  #hero {
    height: 90vh;
    height: calc(var(--vh, 1vh) * 90);
    padding-top: 8vh;

    .row {
      flex-wrap: wrap-reverse;
      height: 100%;
      justify-items: center;
      align-content: space-around;
    }

    .left {
      flex-basis: unset;
    }

    .right {
      flex-basis: unset;

      img {
        max-height: 45vh;
        //width: 80%;
        //margin: 0 12.5%;
      }
    }
  }
  #interactive-stories {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 747px) {

  #hero {
    max-height: 900px;
    .right {
      img {
        max-height: calc(min(400px, 45vh));
      }
    }
    p.subtitle {
      padding-right: 0;
    }
  }

  #interactive-stories, #about {
    margin: 4rem auto;
    h2 {
      margin-bottom: 0.5rem;
    }
  }

  .products {
    max-width: calc(100%);
    gap: 3em;
  }

  .product {
    flex-basis: 100%;
  }

  #about .row p.large-text {
    padding-right: 0;
  }

  #interactive-stories {
    //margin: 4rem auto 4rem auto;
    .gap-3 {
      gap: 0;
    }
  }
}

</style>