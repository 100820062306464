<template>
  <div class="bg-blue">
    <Nav color="teal"></Nav>
    <section id="companion-app" class="padding-main">
      <div class="container-large">
        <h1 class="teal">Companion Apps</h1>
        <div class="col mt-1">
          <CompanionAppBundle v-for="(appInfo,index) in appsInfo" :key="index" :app="appInfo"></CompanionAppBundle>
        </div>
      </div>
    </section>
    <Footer color="blue"></Footer>
  </div>
</template>

<script>
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";
import CompanionAppBundle from "@/components/CompanionAppBundle";

export default {
  name: "CompanionApp",
  components: {Nav, Footer, CompanionAppBundle},
  data() {
    return {
      appsInfo: [
        {
          productImage: 'TBWTTNL_product_thumbnail.png',
          productName: 'The Bear Who Touched The Northern Lights',
          appName: 'The Bear Chapters',
          appIcon: 'the-bear-chapters_app-icon.png',
          description: 'Use this app to turn the puzzle pieces from The Bear Who Touched The Northern Lights into short animated film, telling you a story, chapter by chapter. Do not hesitate to use the special cards (optional and swappable cards) in the puzzle and come back to the app to see how the story changes.',
          appLinks: {
            iOS : 'https://apps.apple.com/app/id1630877428',
            android: 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.redbluestories.TheBearChapters'
          },
          pageName: 'the-bear-chapters-app'
        },
        {
          productImage: 'TFT_product_thumbnail.png',
          productName: 'Tales From The Trails',
          appName: 'Tales From The Trails',
          appIcon: 'TFT_app-icon-rounded.jpg',
          description: 'Use this app to turn the Tales From the Trails postcards into fun little episodic films, turning the artwork into charming animations. Follow the instructions on the app to explore the interactions hidden on each cards!',
          appLinks: {
            iOS : 'https://apps.apple.com/app/tales-from-the-trails/id6466105381',
            android: 'https://play.google.com/store/apps/details?id=com.redbluestories.TalesFromTheTrails'
          },
          pageName: 'tales-from-the-trails-app'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>