<template>
  <Nav></Nav>
  <section id="app">
      <div class="container">
        <img src="../assets/img/the-bear-chapters_app-icon.png">
        <h3>The Bear Chapters App</h3>
        <div class="app-section">
          <h5>Android</h5>
          <p><a href="https://play.google.com/store/apps/details?id=com.redbluestories.TheBearChapters">https://play.google.com/store/apps/details?id=com.redbluestories.TheBearChapters</a></p>
        </div>
        <div class="app-section">
          <h5>iOS</h5>
          <p><a href="https://apps.apple.com/app/id1630877428">https://apps.apple.com/app/id1630877428</a></p>
        </div>
      </div>
   </section>
  <Footer></Footer>
</template>

<script>

import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";

export default {
  name: 'TheBearChaptersApp',
  components: {Nav, Footer},
  data() {
    return {
      redirect: {
        ios: {
          path: 'https://apps.apple.com/app/id1630877428',
          status: true
        },
        android: {
          path: 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.redbluestories.TheBearChapters',
          status: true
        }
      }
    }
  },
  methods: {
    isAppleDevice() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.userAgent)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac"))
    }
  },
  mounted() {
      if (/Android/i.test(navigator.userAgent)) {
        if (this.redirect.android.status) {
          window.location.href = this.redirect.android.path;
        }
      } else if (/iPhone|iPod|iPad|Mac/i.test(navigator.userAgent)) {
        if (this.redirect.ios.status) {
          window.location.href = this.redirect.ios.path;
        }
      }
  }
}
</script>

<style lang="scss" scoped>

#app {
  padding-top: 6em;
  padding-bottom: 6em;

  h3 {
      margin: 1em 0 1em 0;
  }

  .app-section {
     margin-bottom: 1em;
  }

  img {
    max-width: 8em;
    border-radius: 20px;
  }
}

</style>