<template>
  <Banner text="Flat US & CANADA Shipping - 10CAD" @updateBannerState="onBannerUpdate" :color="color"></Banner>
  <nav id="nav" v-if="!isCompact" :class="navClasses">
    <div class="standard">
      <div class="container-large standard-container">
        <router-link to="/home" class="no-hover no-pd">
        <SVGLogoLight v-if="color==='teal'" size="desktop"></SVGLogoLight>
        <SVGLogoDark v-else size="desktop"></SVGLogoDark>
      </router-link>
        <div class="row">
        <div>
          <router-link :to="{ path: '/shop'}" >Shop</router-link>
          <router-link :to="{ path: '/companion-apps'}" >Companion Apps</router-link>
          <router-link :to="{ path: '/home', hash: '#about' }" >About</router-link>
        </div>
<!--        <router-link to="/cart" class="no-hover" id="cart-link">-->
<!--          <svg id="cart" :class="color" width="25" height="25" viewBox="0 0 23 23" fill="#4DAAAA" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M22.6616 5.11172C22.4564 4.72918 22.1625 4.4104 21.8086 4.18647C21.4548 3.96253 21.053 3.84109 20.6425 3.83397H5.43974L4.75086 0.946278C4.68126 0.667524 4.52598 0.422333 4.31031 0.25064C4.09464 0.0789469 3.83129 -0.00913171 3.56315 0.00074937H1.18772C0.872714 0.00074937 0.570614 0.135368 0.347874 0.374991C0.125134 0.614614 0 0.939613 0 1.27849C0 1.61737 0.125134 1.94237 0.347874 2.18199C0.570614 2.42161 0.872714 2.55623 1.18772 2.55623H2.66048L5.93858 15.6659C6.00818 15.9446 6.16346 16.1898 6.37913 16.3615C6.5948 16.5332 6.85815 16.6213 7.12629 16.6114H17.8157C18.0351 16.6107 18.2499 16.5446 18.4365 16.4206C18.6231 16.2966 18.7741 16.1193 18.8728 15.9086L22.7685 7.52665C22.9374 7.1459 23.016 6.7261 22.9973 6.30472C22.9787 5.88334 22.8633 5.47348 22.6616 5.11172ZM17.0793 14.0559H8.02896L6.09298 6.38946H20.6425L17.0793 14.0559Z"/>-->
<!--            <path d="M6.53255 23C7.51649 23 8.31412 22.1419 8.31412 21.0834C8.31412 20.0248 7.51649 19.1667 6.53255 19.1667C5.54861 19.1667 4.75098 20.0248 4.75098 21.0834C4.75098 22.1419 5.54861 23 6.53255 23Z"/>-->
<!--            <path d="M18.4095 23C19.3934 23 20.1911 22.1419 20.1911 21.0834C20.1911 20.0248 19.3934 19.1667 18.4095 19.1667C17.4256 19.1667 16.6279 20.0248 16.6279 21.0834C16.6279 22.1419 17.4256 23 18.4095 23Z"/>-->
<!--          </svg>-->
<!--          <div class="cart-fill" v-if="nbItemInCart != 0">-->
<!--            {{ nbItemInCart }}-->
<!--          </div>-->
<!--        </router-link>-->
      </div>
      </div>
    </div>
  </nav>
  <nav v-show="isCompact" class="mobile row" id="nav-mobile" :class="navClasses">
    <router-link to="/" class="logo-link no-hover">
      <SVGLogoLight v-if="color==='teal'" size="mobile"></SVGLogoLight>
      <SVGLogoDark v-else size="mobile"></SVGLogoDark>
    </router-link>
    <img :src="require('../assets/img/' + imageHamburgerMenuSrc)" alt="menu icon" @click="triggerMobileMenu(true)">
  </nav>
  <nav :class="{active : mobileMenu}" class="mobile-open">
    <div class="nav-container">
      <img src="../assets/img/icon-close.png" alt="close menu icon" @click="triggerMobileMenu(false)">
      <div class="links">
        <router-link :to="{ path: '/shop'}" @click="triggerMobileMenu(false)">Shop</router-link>
        <router-link :to="{ path: '/companion-apps'}" @click="triggerMobileMenu(false)">Companion Apps</router-link>
        <router-link :to="{ path: '/home', hash: '#about' }" @click="triggerMobileMenu(false)">About</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import SVGLogoLight from "@/components/SVGLogoLight";
import SVGLogoDark from "@/components/SVGLogoDark";
import Banner from "@/components/Banner";

export default {
  name: 'Nav',
  props: ['color'],
  components: {
    SVGLogoLight,
    SVGLogoDark,
    Banner
  },
  data() {
    return {
      isCompact: false,
      isMobile: false,
      mobileMenu: false,
      prevScroll: 0,
      isProductDropdownOpen: false,
      isDropDownItemHovered: false,
      hamburgerMenu: {
        teal: 'icon-menu_teal.png',
        blue: 'icon-menu_blue.png'
      },
      onScroll: false
    }
  },
  methods: {
    resizeHandler() {
      this.isCompact = window.innerWidth < 1068;
      this.isMobile = window.innerWidth < 769;
    },
    triggerMobileMenu(state) {
      this.mobileMenu = state;
    },
    onBannerUpdate(state) {
      console.log("state");
      this.isBannerOpened = state;
      let navElt = this.getNavElt();
      console.log(navElt.classList);
      if (!state) {
        navElt.classList.remove("banner-offset");
      } else {
        navElt.classList.add("banner-offset");
      }
    },
    showDropdown(id) {
      var dropdown = document.getElementById(id);
      this.isProductDropdownOpen = !this.isProductDropdownOpen;
      if (this.isProductDropdownOpen) {
        dropdown.classList.add("visible");
      } else {
        dropdown.classList.remove("visible");
      }
    },
    getNavElt(){
      var navElt;
      if (this.isCompact) {
        navElt = document.getElementById("nav-mobile");
      } else {
        navElt = document.getElementById("nav");
      }
      return navElt;
    },
    handleScroll() {
      var currentScrollPos = window.scrollY;
      var navElt = this.getNavElt();

      if (currentScrollPos < navElt.clientHeight + (this.isBannerOpened ? 20 : 0)){
        navElt.classList.remove("onScroll");
        this.onScroll = false;
        navElt.style.position = 'absolute';
        if (this.isBannerOpened) {
          navElt.style.top = "20px";
        } else {
          navElt.style.top =  '0';
        }
      } else {
        if (this.prevScroll > currentScrollPos) {
          navElt.style.top = this.bannerOffset;
          navElt.style.position = "fixed";
          navElt.classList.add("onScroll");
          this.onScroll = true;
        } else {
          navElt.style.top = "-150px";
          navElt.classList.remove("onScroll");
          this.onScroll = false;
        }
      }
      this.prevScroll = currentScrollPos;
    }
  },
  mounted() {
    this.isCompact = window.innerWidth < 1068;
    this.isMobile = window.innerWidth < 769;
    window.addEventListener("resize", this.resizeHandler);
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  computed:{
    isBannerOpened() {
      return this.$store.getters.getBannerState;
    },
    nbItemInCart() {
      return this.$store.getters.getNbItemsInCart;
    },
    imageHamburgerMenuSrc() {
      if (this.color === 'teal') {
        return this.hamburgerMenu.teal;
      } else {
        return this.hamburgerMenu.blue;
      }
    },
    navClasses() {
      return {
        'banner-offset': this.isBannerOpened,
        'blue': this.color === 'blue',
        'teal': this.color === 'teal',
        'bg-teal' : this.color === 'blue' & this.onScroll,
        'bg-blue' : this.color === 'teal' & this.onScroll,
      }
    },
    bannerOffset() {
      if (this.isBannerOpened){
        if (this.isCompact || this.isMobile) {
          return "25px"
        } else {
          return "28px";
        }
      } else {
        return "0";
      }
    },

  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables.scss';


svg {
  &#logo {
    width: 80px;
    padding-top: 20px;

    &.mobile {
      width: 80px;
      margin-left: 10px;
      padding-top: 0px;
    }
  }

  &#cart {
    width: 25px;
    height: auto;
    transition: all .1s ease-in;

    &.teal {
      fill: $teal;
       &:hover {
         fill: $teal-hover;
       }
    }

    &.blue {
      fill: $blue;
      &:hover {
        fill: $blue-hover;
      }
    }

    &:hover {
      cursor: pointer;
      transition: all .1s ease-in;
    }
  }
}

#cart-link {
  position: relative;

  .cart-fill {
    position: absolute;
    top: -6px;
    right: -8px;
    height: 18px;
    width: 18px;
    border-radius: 18px;
    background-color: $cream;
    color: $blue;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
}

nav {
  top: 0;
  padding-top: 0;
  width: 100%;
  //transition: top 0.2s, background-color .2s;
  position: absolute;
  z-index: 998;
  &.banner-offset {
    top : 33px;
  }

  .standard-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
  }

  &.onScroll {
    a, button {
      color: $light-blue;
      &:hover, &.router-link-active {
        color: $teal;
        border-bottom: 2px solid $teal;
      }
    }
    .standard {
     // padding: 10px 0;
      box-shadow: 0px 1px 11px 0 $blue-hover;

      svg#logo {
        transition: 1ms ease-in-out all;
        width: 60px;
        padding-top: 10px;
      }
    }

    &.mobile {
      padding: .5em 1em;
      box-shadow: 0px 1px 11px 0 $blue-hover;
    }

    //#cart-link .cart-fill {
    //  background-color: $teal;
    //  color: $blue;
    //}

    a.arrow::after, button.arrow::after {
      border-left: 1px solid $light-blue;
      border-bottom: 1px solid $light-blue;
    }

    a.arrow:hover::after, button.arrow:hover::after {
      border-left: 1px solid $teal;
      border-bottom: 1px solid $teal;
    }
  }
}

nav {
  &.blue {
    a, button, p {
       color: $blue;
      &:hover, &.router-link-active {
        color: $blue-hover;
        border-bottom: 2px solid $blue-hover;
      }
    }
  }

  &.teal {
    a, button, p {
      color: $teal;
      &:hover, &.router-link-active {
        color: $teal-hover;
        border-bottom: 2px solid $teal-hover;
      }
    }
  }

  a, button, p {
    font-size: .8em;
    font-weight: 500;
    line-height: 18.7px;
    margin: .2em 3em .2em 0;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    position: relative;

    &:hover, &.router-link-active {
      cursor: pointer;
    }
  }

  a.no-hover, button.no-hover {
    &:hover {
      border-bottom: 2px solid transparent;
    }
    &.router-link-active {
      border-bottom: 2px solid transparent;
    }
  }

  a.no-pd {
    padding: 0;
    margin: 0;
  }
}

nav#nav-mobile {
  width: calc(100% - 3em);

  &.onScroll {
    width: calc(100% - 2em);
  }
}

nav.mobile {
  width: -webkit-fill-available;
  position: fixed;
  padding: 1.5em;
  z-index: 900;
  justify-content: space-between;
  align-items: center;
  //transition: top 0.3s;

  &.banner-offset {
    top : 20px;
  }

  img {
    max-width: 25px;
    max-height: 25px;
    cursor: pointer;
  }

  svg {
    width: 100px;
  }

  a.logo-link {
    max-width: 40px;
    width: 100%;

  }
}

nav.mobile-open {
  transform: translateY(-500px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: $blue;
  z-index: 999;
  opacity: 0;
  //transition: all .5s ease-in-out;
  filter: drop-shadow(-2px 4px 4px rgba(0, 0, 0, 0.25));

  .nav-container, .links {
    display: flex;
    flex-direction: column;

    a, p {
      font-size: 1em;
      font-weight: 600;
      color: $cream;
      line-height: 1.5em;

      &.secondary-link {
        font-weight: 400;
        font-size: .9em;
      }
    }

    img {
      align-self: flex-end;
      width: 30px;
      cursor: pointer;
    }
  }

  .nav-container {
    padding: 2em;
  }
}

nav.mobile-open.active {
  transform: translateY(0);
  opacity: 1;
  //transition: all .5s ease-in-out;
}

img {
  &.background-hero {
    position: absolute;
    width: 100%;
    top: 0;
  }

  &.sea-hero {
    position: absolute;
    width: 100%;
    top: 68vh;
  }
}

a.arrow::after, button.arrow::after {
  content: "";
  width: 7px;
  margin-left: 7px;
  height: 7px;
  display: inline-block;
  border-left: 1px solid $font-dark;
  border-bottom: 1px solid $font-dark;
  transform: translateY(-2px) rotate(-45deg);
}

a.arrow:hover::after, button.arrow:hover::after {
  border-left: 1px solid $blue;
  border-bottom: 1px solid $blue;
}

//nav, nav.onScroll {
//  #product-dropdown {
//    position: absolute;
//    display: none;
//    a {
//      font-size: 1em;
//      color: $blue;
//      text-decoration: none;
//      border-bottom: none;
//
//      &:hover {
//        text-decoration: none;
//        border: none;
//      }
//    }
//
//    &.visible {
//      top: 25px;
//      left: -100px;
//      display: flex;
//      flex-direction: column;
//      width: max-content;
//      text-align: left;
//      border-radius: 15px;
//      background-color: $teal;
//      filter: drop-shadow(-2px 4px 4px rgba(0, 0, 0, 0.15));
//      a:first-child {
//        border-radius: 15px 15px 0 0;
//      }
//
//      a:last-child {
//        border-radius: 0px 0px 15px 15px;
//      }
//
//      a {
//        padding: 15px;
//        margin: 0;
//        &:hover {
//          background-color: $teal-hover;
//          color: $light-blue;
//        }
//
//        &.router-link-active {
//          border-bottom: 2px solid transparent;
//        }
//      }
//    }
//  }
//}
a.secondary-link::before {
  width: 2em;
  content: " ";
  display: inline-block;
  margin-right: 5px;
  border-top: 1px solid $teal;
  transform: translateY(-5px);
}


</style>