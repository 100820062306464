<template>
 <Nav color="blue"></Nav>
  <section id="returns" class="padding-main">
    <div class="container-large">
      <div class="intro title-only">
        <h1 class="blue">Returns & Refunds</h1>
      </div>
      <div>
        <h4>Returns</h4>
        <div class="mb-1">
          <p>We do not accept returns. However, if you receive our products with any defects, please reach out to <a href="mailto:hello@redbluestories.com">hello@redblustories.com</a> so we can make it right!</p>
        </div>
      </div>
      <div>
        <h4>Refunds</h4>
        <div class="mb-1">
          <p>We only provide refunds in case of order cancellation or damaged items, on a case by case basis.</p>
        </div>
      </div>
      <div>
        <h4>Order Cancellation</h4>
        <div class="mb-1">
          <p>We only allow order cancellation & refunds for orders that have not yet shipped. <br> If you do want to cancel your order, and it hasn't shipped (you will receive a email notification when it does), please email at <a href="mailto:hello@redbluestories.com">hello@redblustories.com</a>. We will process your request within 48 hours and you will receive a notification from our payment handler about your refund.</p>
        </div>
      </div>
    </div>
  </section>
  <Footer color="teal"></Footer>
</template>

<script>

import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";
export default {
  name: 'ReturnsAndRefunds',
  components: {Footer, Nav},
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables.scss';

#returns {

  h5 {
    font-weight: 600;
  }
}
@media only screen and (max-width: 1280px) {
  #returns {
    margin-bottom: 2em;
  }
}

@media only screen and (max-width: 920px) {
  #returns {
    min-height: 40vh;
  }
}

</style>