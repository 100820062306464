<template>
  <Nav color="blue"></Nav>
  <section id="privacy-policy" class="padding-main">
    <div class="container-large">
      <div class="intro title-only">
        <h1 class="blue">Privacy Policy</h1>
        <div class="buttons row">
          <button class="dark" :class="activePolicy === 'website' ? '' : 'outline'" @click="selectPP('website')">redbluestories.com</button>
          <button class="dark" :class="activePolicy === 'the-bear-chapters' ? '' : 'outline'" @click="selectPP('the-bear-chapters')">The Bear Chapters (app)</button>
          <button class="dark" :class="activePolicy === 'tales-from-the-trails' ? '' : 'outline'" @click="selectPP('tales-from-the-trails')">Tales from the trails (app)</button>
        </div>
      </div>
      <div class="content" v-if="activePolicy === 'the-bear-chapters'">
        <p>By using the app <strong>"The Bear Chapters"</strong> you are consenting to our policies regarding the collection, use and disclosure of personal information set out in this privacy policy.</p>
        <h5>Collection of Personal Information</h5>
        <p>We do not collect, store, use or share any information, personal or otherwise.</p>
        <h5>Email</h5>
        <p>If you email the developer for support or other feedback, the emails with email addresses will be retained for quality assurance purposes. The email addresses will be used only to reply to the concerns or suggestions raised and will never be used for any marketing purpose.</p>
        <h5>Disclosure of Personal Information</h5>
        <p>We will not disclose your information to any third party except if you expressly consent or where required by law.</p>
        <h5>Contacting Us</h5>
        <p>If you have any questions regarding this privacy policy, you can email <a href="mailto:hello@redbluestories.com">hello@redbluestories.com</a></p>
      </div>
       <div class="content" v-if="activePolicy === 'tales-from-the-trails'">
        <p>By using the app <strong>"Tales from the trails"</strong> you are consenting to our policies regarding the collection, use and disclosure of personal information set out in this privacy policy.</p>
        <h5>Collection of Personal Information</h5>
        <p>We do not collect, store, use or share any information, personal or otherwise.</p>
        <h5>Email</h5>
        <p>If you email the developer for support or other feedback, the emails with email addresses will be retained for quality assurance purposes. The email addresses will be used only to reply to the concerns or suggestions raised and will never be used for any marketing purpose.</p>
        <h5>Disclosure of Personal Information</h5>
        <p>We will not disclose your information to any third party except if you expressly consent or where required by law.</p>
        <h5>Contacting Us</h5>
        <p>If you have any questions regarding this privacy policy, you can email <a href="mailto:hello@redbluestories.com">hello@redbluestories.com</a></p>
      </div>
      <div class="content" v-if="activePolicy === 'website'">
        <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from redbluestories.com (the “Site”) owned and operated by Red Plus Blue Stories Inc. (the "Company").</p>
        <p>In this policy, we lay out: what data we collect and why; how your data is handled; and your rights with respect to your data. We will never sell your data.</p>
        <h4>What we collect and why</h4>
        <p>Our guiding principle is to collect only what we need. Here’s what that means in practice:</p>
        <h5>Website interactions</h5>
        <p>We collect information about your browsing activity for analytics and statistical purposes such as conversion rate testing and experimenting with new product designs. This includes, for example, your browser and operating system versions, your location, which web pages you visited and how long they took to load, and which website referred you to us. The web analytic we use, Umami, Umami does not collect any personal information, does not use cookies, does not track users across websites, and is GDPR compliant.</p>
        <h5>Advertising and Cookies</h5>
        <p>- We do not run any ads from third-party platforms</p>
        <p>- We do not use third-party cookies or send any information to third-parties</p>
        <h5>Billing information</h5>
        <p>When you make a purchase or attempt to make a purchase through our Site, you are redirected to Stripe, which handles our payments. We use their platform to collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number.  We refer to this information as “Order Information.” </p>
        <p>We use the Order Information that we collect to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).  Additionally, we use this Order Information to:
          communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services. </p>
        <p>We’ll never sell your personal information to third parties, and we won’t use your name or company in marketing statements without your permission either.</p>
        <h5>Voluntary correspondence</h5>
        <p>When you email Company with a question or to ask for help, we keep that correspondence, including your email address, so that we have a history of past correspondence to reference if you reach out in the future.</p>
        <p>We also store information you may volunteer, for example, written responses to surveys. We will only do so with your express consent.</p>
        <h4>Changes & questions</h4>
        <p>We may update this policy as needed to comply with relevant regulations and reflect any new practices.</p>
        <p>Have any questions, comments, or concerns about this privacy policy, your data, or your rights with respect to your information? Please get in touch by emailing us at <a href="mailto:hello@redbluestories.com">hello@redbluestories.com</a> and we’ll be happy to try to answer them!</p>
        <p>Adapted from the Basecamp open-source policies / CC BY 4.0</p>
        <p>Last Updated Sept, 2023</p>
      </div>
    </div>
  </section>
  <Footer color="teal"></Footer>
</template>

<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";

export default {
  name: 'PrivacyPolicy',
  components: {Nav, Footer},
  data() {
    return {
      activePolicy: 'the-bear-chapters'
    }
  },
  methods: {
    selectPP(val) {
      this.activePolicy = val;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables.scss';

#privacy-policy {

  .buttons {
    button {
      margin-right: 2em;
      border: 1px solid transparent;
      margin-bottom: 0;

      &.outline {
        border: 1px solid $blue;
      }
    }
  }
}
</style>