<template>
  <Nav color="blue"></Nav>
  <section id="thank-you" class="padding-main">
    <div class="row">
      <div class="container-large col">
        <Icon icon="line-md:confirm-circle" color="#39bc9d" width="100" />
        <h3>Thank you for your order!</h3>
        <p>You should receive a confirmation email with your order number. Orders are typically sent out within 2-3 business days.<br> We will email you once your order has shipped.</p>
        <p>If the meantime, if you have any questions, please contact <br><a href="mailto:hello@redbluestories.com">hello@redbluestories.com</a></p>
      </div>
    </div>
  </section>
  <Footer color="teal"></Footer>
</template>

<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";
import {Icon} from '@iconify/vue';

export default {
  name: 'ThankYou',
  components: {Nav, Footer, Icon},
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    isCompact() {
      return window.innerWidth < 640;
    }
  },
  mounted() {
    this.$store.dispatch('clearCart');
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables.scss';

#thank-you {
  justify-content: center;

  .row  {
    justify-content: space-between;


    h3 {
      margin: 1em 0 .5em 0;
    }
    img {
      max-height: 80vh;
      max-width: calc(max(20vw, 315px));
    }

    p {
      margin-bottom: 1em;
    }
  }
}
</style>