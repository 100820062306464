<template>
  <div class="col" >
    <div>
    <img v-lazy="{src: require(`../assets/img/product/${product.itemId}/${product.info.productImages[product.info.thumbnailImg].path}`), loading: require(`../assets/img/product/${product.itemId}/${product.info.productImages[product.info.thumbnailImg].load}`)}" @click="goToLink(product.link)">
    <p class="title blue">{{product.info.projectName}}</p>
    <p class="tagline blue">{{product.info.subtitle}}</p>
      <p class="description blue">{{product.info.oneLiner}}</p>
    </div>
    <router-link :to="{ name: 'product', params: { productName: product.slug }}" class="button-outline">Learn more</router-link>
  </div>
</template>

<script>
import router from '../router/index.js'

export default {
  name: "ProductLearnMore",
  props: ['product'],
  methods: {
    goToLink(link) {
      router.push({ path: link })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables.scss";
img {
  border-radius: 20px;
  margin-bottom: 1em;
  cursor: pointer;
}

p {
  &.title {
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 1.4rem;
  }

  &.tagline {
    font-style: italic;
    font-size: 1.2rem;
  }
}

.col {
  justify-content: space-between;
}
</style>